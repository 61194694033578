module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.oas.nu"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WE ARE OAS","short_name":"OAS","start_url":"/","theme_color":"#5b2160","background_color":"#0e0c0e","icon":"src/assets/images/favicon-512x512.png","include_favicon":false,"icons":[{"src":"/static/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/static/favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/static/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/static/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/static/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/static/favicon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/static/favicon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/static/favicon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5M2XHQH","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"357028124673859"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
