// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-page-sections-js": () => import("../src/templates/page-sections.js" /* webpackChunkName: "component---src-templates-page-sections-js" */),
  "component---src-templates-press-template-js": () => import("../src/templates/pressTemplate.js" /* webpackChunkName: "component---src-templates-press-template-js" */),
  "component---src-templates-case-template-js": () => import("../src/templates/caseTemplate.js" /* webpackChunkName: "component---src-templates-case-template-js" */),
  "component---src-templates-inspo-template-js": () => import("../src/templates/inspoTemplate.js" /* webpackChunkName: "component---src-templates-inspo-template-js" */),
  "component---src-templates-coworker-template-js": () => import("../src/templates/coworkerTemplate.js" /* webpackChunkName: "component---src-templates-coworker-template-js" */),
  "component---src-templates-press-cat-template-js": () => import("../src/templates/pressCatTemplate.js" /* webpackChunkName: "component---src-templates-press-cat-template-js" */),
  "component---src-templates-case-cat-template-js": () => import("../src/templates/caseCatTemplate.js" /* webpackChunkName: "component---src-templates-case-cat-template-js" */),
  "component---src-templates-inspo-cat-template-js": () => import("../src/templates/inspoCatTemplate.js" /* webpackChunkName: "component---src-templates-inspo-cat-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artiklar-js": () => import("../src/pages/artiklar.js" /* webpackChunkName: "component---src-pages-artiklar-js" */),
  "component---src-pages-cases-js": () => import("../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-press-js": () => import("../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */)
}

